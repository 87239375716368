<template>
  <div class="clue-item" @click="$emit('goDetail')">
    <div class="clue-info">
      <div class="user-status">
        <shortConsumerInfo :data="dataSource" :showLevel="false" :isDesensitization="true"></shortConsumerInfo>
        <!-- 线索培育状态 -->
        <div :class="['status', `status_${dataSource.hatchState}`]">{{ dataSource.hatchState | dictText($store) }} </div>
      </div>
      <Labels v-if="dataSource.labels && dataSource.labels.length > 0" :data="dataSource.labels"></Labels>
      <!-- 新分配才有来源渠道 -->
      <div v-if="dataSource.hatchState==='1000005'" class="van-ellipsis">
        <span class="title">来源渠道：<span style="color: #0040C5">{{ dataSource.onlineName }}</span></span>
        <span v-if="dataSource.onlineName"> | </span>
        <span>{{ dataSource | sourceText }}</span>
      </div>
      <!-- 创建时间 -->
      <div class="van-ellipsis">
        <span class="title">创建时间：</span>
        <span>{{ dataSource.createTime }}</span>
      </div>
      <!-- <div  v-if="dataSource.userWeChat" class="van-ellipsis">
        <span class="title">最新记录：</span>
        <span >{{ dataSource.followTime }} | </span>
        <span>{{ dataSource.followRemark }}</span>
      </div>
      <div class="van-ellipsis">
        <span class="title">意向车型：</span>
        <span>{{ dataSource.seriesName }} {{ dataSource.modelName }} </span>
      </div> 
      <div class="van-ellipsis">
        <span class="title">培育截止时间：</span>
        <span>{{ dataSource.planTime | timeText}} </span>
      </div>  -->
      <!-- 分配到店 -->
      <template v-if="dataSource.hatchState==='1000012'">
        <div class="van-ellipsis">
          <span class="title">分配门店：</span>
          <span>{{ dataSource.dealerName || '--' }}</span>
        </div>
        <div class="van-ellipsis">
          <span class="title">分配时间：</span>
          <span>{{ dataSource.distributeTime | followTimeText }}</span>
        </div>
        <div class="van-ellipsis">
          <span class="title">门店跟进状态：</span>
          <span>{{ dataSource.state | dictText($store) }}</span>
        </div>
      </template>
      <!-- 除了新分配/已分配门店 都有最新记录 -->
      <div v-if="!['1000005','1000012'].includes(dataSource.hatchState)" class="van-ellipsis">
        <span class="title">最新记录：</span>
        <!-- 培育中需要展示时间 -->
        <span v-if="dataSource.hatchState==='1000010'">{{ dataSource.followTime }} | </span>
        <!-- 战败审核/无效审核需要展示原因 -->
        <span v-if="['1000013','1000014'].includes(dataSource.hatchState)">{{ dataSource.sysRemark }} | </span>
        <span>{{ dataSource.followRemark }}</span>
      </div>
      <!-- 新分配和培育中有意向信息 -->
      <div v-if="['1000005','1000010'].includes(dataSource.hatchState)" class="van-ellipsis">
        <span class="title">意向信息：</span>
        <span>{{ dataSource.seriesName }} {{ dataSource.modelName }} </span>
      </div>
      <!-- 战败/无效/已分配门店/提前到店 不展示培育截止日期 -->
      <div v-if="!['1000016','1000017','1000012','1000018'].includes(dataSource.hatchState)" class="van-ellipsis flex">
        <div>
          <!-- 无效和战败审核中显示 -->
          <span v-if="['1000013','1000014'].includes(dataSource.hatchState)" class="title">审核截止时间：</span>
          <!-- 新分配和培育中显示 -->
          <span v-else class="title">培育截止时间：</span>
          <span>{{ dataSource.planTime | timeText}}</span>
        </div>
      </div>
      <div v-if="['1000016','1000017'].includes(dataSource.hatchState)" class="van-ellipsis">
        <span  v-if="dataSource.hatchState === '1000016'" class="title">无效时间：</span>
        <span  v-if="dataSource.hatchState === '1000017'" class="title">战败时间：</span>
        <span>{{ dataSource.followTime | timeText}}</span>
      </div>
      <van-checkbox
        v-if="isDistributionState"
        :value="distributionIds.includes(dataSource.id)"
        shape="square"
        class="check-item"
        @click.native.stop="onClick">
      </van-checkbox>
    </div>
    <div class="bottom">
      <div class="follow-user">外呼客服：{{ dataSource.hatchUserName||'--' }} </div>
      <slot name="actions">
        <div class="icons">
          <!-- <externalUserIcon v-if="!dataSource.userWeChat && showIcon" :id="dataSource.id" class="icon" @selectUser="selectUser"/> -->
          <qwIcon v-if="dataSource.userWeChat" :leadId="dataSource.id" :externalUserIds="dataSource.userWeChat" :showCopyText="false" class="icon"/>
          <!-- <wxIcon v-if="!dataSource.userWeChat && showIcon" class="icon"/> -->
          <phoneIcon v-if="dataSource.userMobile" :phone="dataSource.userMobile" class="icon"/>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import { dateFormat } from '@/utils'
import Labels from '@/components/card-view/label.vue'
import IntentionLevel from '@/components/card-view/intention-level.vue'
import CountDown from '@/modules/components/countDown'
import moment from 'moment'
export default {
  components:{ shortConsumerInfo, externalUserIcon, phoneIcon, qwIcon, wxIcon, Labels, IntentionLevel, CountDown },
  filters: {
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : '暂无'
    },
    timeText(data){
      const year = moment(data).format('YYYY')
      const theYear = new Date().getFullYear()
      if (year!=theYear){
        return dateFormat(data, 'YYYY-MM-DD HH:mm')
      } else {
        return dateFormat(data, 'MM-DD HH:mm')
      }
    },
    dictText(val,$store){
      const dictHash = $store.getters.dictHash
      const prefix = val.substring(0,4)
      const list = dictHash[prefix] || []
      let result = '未知'
      const theOne = list.find(item=>item.code===val)
      if (theOne?.name) result = theOne.name
      console.log('result:',result)
      return result
    },
    followTimeText(date){
      if (!date) return '--'
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    isDistributionState:{
      type: Boolean,
      default: false,
    },
    distributionIds:{
      type: Array,
      default:()=>([])
    }
  },
  data() {
    return {
    }
  },
  computed:{
    showIcon() {
      return !['1000013', '1000014', '1000016', '1000017'].includes(this.dataSource.hatchState)
    },
  },
  methods: {
    selectUser(params={}){
      const { externalUserid,name } = params
      this.$set(this.dataSource, 'customerName', name )
      this.$set(this.dataSource, 'userWeChat', externalUserid)
    },
    onClick(){
      this.$emit('change',this.dataSource)
    },
  }
}
</script>
<style scoped lang="less">
@checkbox-color:#0D171A;
.clue-item{
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 0px 12px;
  font-size: 13px;
  .clue-info{
    position: relative;
    .user-status{
      display: flex;
      justify-content: space-between;
      .status{
        width: 78px;
        text-align: right;
        &.status_1000005{
          color: #0040C5
        }
        &.status_1000010{
          color: #FFD100
        }
        &.status_1000013,&.status_1000014{
          color: #E4002C
        }
        &.status_1000016,&.status_1000017{
          color: rgba(13,23,26,0.45) !important
        }

      }
    }
    .level{
      background-color: #EED484;
      padding: 1px 4px;
      border-radius: 8px;
      display: inline-block;
      color: #0D171A;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      margin-left: 8px;
    }
    >div{
      margin-bottom: 8px;
    }
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(13,23,26,0.05);
    .follow-user{
      font-size: 13px;
      color: #0D171A;
      line-height: 20px;
    }
    .icons{
      display:flex;
      padding: 8px 0 12px 0;
      justify-content: space-between;
      .icon{
        margin-left: 16px;
      }
    }
  }
  .van-ellipsis{
    color: @black;
    &.flex{
      display: flex;
      justify-content: space-between;
    }
    .title{
      color: rgba(13,23,26,0.45);
    }
  }

  .check-item{
    position: absolute;
    right: 12px;
    top: 50%;
    // transform: translateX(-50%);
    z-index: 1;
    /deep/.van-checkbox__icon{
        font-size: 16px;
        .van-icon{
            border: 2px solid rgba(13,23,26,0.45);
        }
    }

    /deep/.van-checkbox__icon--checked .van-icon{
        background-color: @checkbox-color;
        border-color: @checkbox-color;
        line-height: 1;
    }
  }
}
</style>
