<!--
* @Author: tianzl
* @Date: 2023-12-05 13:37:21
* @LastEditors: tianzl
* @LastEditTime: 2023-12-05 13:37:21
* @Description: 线索培育
 -->
 <template>
  <div class="clue-incubation">
    <search-bar-new
      placeholder-text="请输入客户姓名/电话/客服"
      :active="Object.values(filterParams).length > 0"
      :str="searchStr"
      :showScreen="true"
      :showCategory="true"
      :actions="actions"
      @input="onInput"
      @search="onRefresh"
      @screening="filterPanelVisible = true"
      @operation="operation">
    </search-bar-new>
    <!-- 筛选面板 -->
    <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    />
    <VanTabs
      :queryType="activeTab"
      :tabList="tabList"
      :isTotal="false"
      @change="changeTab">
    </VanTabs>
    <p v-if="activeTab!==1" class="total">共{{ tabList[activeTab].total  }}条数据</p>
    <OptionsBox v-else
      :optionsList="optionsList"
      :subType="subType"
      style="padding-bottom: 0px;"
      @change="subTabChange">
    </OptionsBox>
    <List ref="list" :key="activeTab || 1" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <clue-item :dataSource="item" :isDistributionState="isDistributionState"
        :distributionIds="distributionIds"  @change="onCheckChanged" @goDetail="goDetail(item)"/>
      </template>
    </List>
    <div v-if="isDistributionState" class="action-bar">
      <div class="left">
        <span @click="cleanDistribution">{{
          $t('取消')
        }}</span>
        <span @click="onAllChecked">{{
          $t('全选')
        }}</span>
      </div>
      <div class="right">
        <van-button square color="#EED484" @click="onDistribution">
          {{ $t('分配')
          }}{{ distributionIds.length ? `(${distributionIds.length})` : "" }}
        </van-button>
      </div>
    </div>
    <!-- 员工列表面板 -->
    <BusinessFilters
      ref="filter"
      v-model="showDistribution"
      :options="distributionOptions"
      :defaultActiveKey="['paraAccountNo']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution = false"
      @submit="distributionFilter"
    >
    </BusinessFilters>
  </div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import FilterPanel from '@/components/filter-panel'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import ClueItem from './components/clue-item'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import adminService from '@/services/baseDataServices.js'
import formatParams from '@/utils/formatParams'
import OptionsBox from '@/components/OptionsBox'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { clueListPost,listCountPost,getHatchRuleDetail,syncLeadAllocation } from '@/services/clueIncubationServices.js'
import clueServices from '@/services/clueServices'
import { arrayFormat } from '@/utils/index.js'
import baseDataServices from '@/services/baseDataServices'
export default {
  name: 'clueIncubation',
  components: { SearchBarNew,FilterPanel,VanTabs,List,ClueItem,OptionsBox,BusinessFilters },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueIncubation')
    })
  },
  data(){
    const that = this
    return {
      searchStr: '',
      filterParams: {},
      filterPanelVisible: false,
      filterOptions:[],
      activeTab: 0,
      tabList:[
        { text: '全部', value: 0,total:0, key:'all' },
        { text: '已加微', value: 1, total: 0 , key:'today' },
        { text: '已邀请加微', value: 2, total: 0 , key:'overdue' },
      ],
      actions:[{ 
        text: this.$t('重新分配文本客服'),
        key: 'distribute',
        premission: 'BTN-CLUE-DISTRIBUTE', 
      }],
      subType: 1,
      optionsList: [
        { text: '微信线索', value: 1, 
          get total(){
            return that.countMap.wechatNum || 0
          }, key: 'wechatNum' },
        { text: '电话线索', value: 2, 
          get total(){
            return that.countMap.mobileNum || 0
          }, key: 'mobileNum' },
      ],
      countMap:{
        wechatNum: 0,
        mobileNum: 0,
        // today:0,
        // todayFirst:0,
        // todayFollow:0,
        // overdue:0,
        // overdueFirst:0,
        // overdueFollow:0
      },
      isDistributionState: false,
      distributionIds:[],
      showDistribution: false,
      relateEmployee:[]
    }
  },
  computed: {
    ...mapGetters({
      dictHash:'dictHash',
      userInfo: 'userInfo',
      getMenu:'app/getMenu',
    }),
    distributionOptions() {
      const that = this
      return [
        {
          name: '分配',
          type: 'SELECT',
          field: 'paraAccountNo',
          multiple: false,
          defaultValue: '',
          nullable: true,
          get items() {
            return that.relateEmployee || []
          },
        },
        // {
        //   name: '',
        //   type: 'B_PEOPLE_SELECTION',
        //   field: 'saIdList',
        //   isSelection: true,
        //   valueType: 'object',
        //   shopIds: '',
        //   height: '80vh',
        //   roleCodes: '1014008',
        // },
      ]
    },
  },
  watch:{
    dictHash:{
      handler(){
        // 过滤条件
        this.createFilterOptions()
      },
      immediate: true
    }
  },
  activated() {
    this.onRefresh()
  },
  async mounted(){
    // 获取列表数量
    // this.getCount()
  },
  methods:{
    async getCount(){
      const params = {
        ...this.filterParams,
        searchStr: this.searchStr,
        queryType:this.activeTab,
      }
      const res = await listCountPost(params)
      this.countMap = res || {}
      // Object.assign(this.countMap,res)
      // if (res){
      // this.$set(this,'countMap',res)
      // const { today,overdue } = res
      // this.tabList[1].total = today || 0
      // this.tabList[2].total = overdue || 0
      // this.renderCount()
      // }
    },
    // renderCount(){
    //   const { todayFirst,todayFollow,overdueFirst,overdueFollow } = this.countMap
    //   if (this.activeTab===1){
    //     this.optionsList[0].total = todayFirst || 0
    //     this.optionsList[1].total = todayFollow || 0
    //   } else {
    //     this.optionsList[0].total = overdueFirst || 0
    //     this.optionsList[1].total = overdueFollow || 0
    //   }
    // },
    onInput(value) {
      this.searchStr = value
    },
    onRefresh(){
      this.cleanDistribution()
      this.getCount()
      this.$refs.list.onRefresh()
    },
    operation({ key }){
      if (key==='create'){
        this.$router.push({
          path:'/clue-incubation/create'
        })
      } else if (key === 'distribute'){
        this.isDistributionState = true
      }
    },
    onFilter(params){
      this.filterParams = formatParams(params, false)
      if (params.createTimeStart||params.createTimeEnd){
        this.filterParams.createTime = {}
      }
      if (params.planTimeStart||params.planTimeEnd){
        this.filterParams.planTime = {}
      }
      // 创建时间
      if (params.createTimeStart){
        this.filterParams.createTime.start = this.filterParams.createTimeStart
      }
      if (params.createTimeEnd){
        this.filterParams.createTime.end = this.filterParams.createTimeEnd
      }
      // 培育截止时间
      if (params.planTimeStart){
        this.filterParams.planTime.start = this.filterParams.planTimeStart
      }
      if (params.planTimeEnd){
        this.filterParams.planTime.end = this.filterParams.planTimeEnd
      }
      this.filterPanelVisible = false
      this.onRefresh()
    },
    changeTab(val){
      this.cleanDistribution()
      this.activeTab = val
      if (val === 1){
        this.getCount()
      }
      // this.renderCount()
      this.$refs.list.onRefresh()
    },
    subTabChange(val){
      this.cleanDistribution()
      this.subType = val
      this.$refs.list.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj={}) {
      const params = {
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        queryType: this.activeTab,
      }
      if (this.activeTab == 1) {
        params.subType = this.subType
      } else if (this.activeTab == 0) {
        params.subType = 0
      }  
      loading.showLoading()
      return clueListPost(params).then(res => {
        loading.hideLoading()
        if ([0,2].includes(this.activeTab) ) this.tabList[this.activeTab].total = res.page.totalCount
        // res.dataList[0].hatchState = '1000010'
        return res
      })
    },
    // 搜索面板条件
    async createFilterOptions(){
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        if (dictHash){
          let list = dictHash[dictType] || []
          if (dictType === 1000) {
            list = list.filter(({ code }) => { return !['1000015'].includes(code) })
          }
          if (dictType === 2000) {
            list = list.filter(({ code }) => { return ['2000005','2000010','2000050', '2000052', '2000055', '2000057', '2000065','2000040','2000045'].includes(code) })
          }
          return list.map(({ code, id, name }) => ({
            id,
            label: name,
            value: code,
          }))
        } else {
          return []
        }
      }

      const ret = []
      // 培育状态
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '培育状态',
        field: 'hatchStates',
        multiple: true,
        options: getOptionsByDictType(1000),
      })
      // 线索创建时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: '线索创建时间',
        start: {
          field: 'createTimeStart',
          value: this.filterParams.createTimeStart
        },
        end: {
          field: 'createTimeEnd',
          value: this.filterParams.createTimeEnd
        },
      })
      // 培育截止时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: '培育截止时间',
        start: {
          field: 'planTimeStart',
          value: this.filterParams.planTimeStart
        },
        end: {
          field: 'planTimeEnd',
          value: this.filterParams.planTimeEnd
        },
      })
      // 意向信息
      const seriesList = await adminService.getAllSeriesModels()
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '意向信息',
        field: 'seriesCodes',
        multiple: true,
        options: seriesList.map(({ code, name, models }) => ({ id: code, label: name, value: code, modelList: models })),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'modelCodes',
        multiple: true,
        options: [],
      })
      // 门店跟进状态
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '门店跟进状态',
        field: 'states',
        multiple: true,
        options: getOptionsByDictType(2000),
      })
      // 是否已到店
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '是否已到店',
        field: 'storeOfFlag',
        options: [
          { id: uuidv4(), label: '否', value: 0 },
          { id: uuidv4(), label: '是', value: 1 },
        ],
      })
      // 是否已试驾
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '是否已试驾',
        field: 'drivingFlag',
        options: [
          { id: uuidv4(), label: '否', value: 0 },
          { id: uuidv4(), label: '是', value: 1 },
        ],
      })
      // 来源渠道
      const channelList = await adminService.getAllChannelList()
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '来源渠道',
        field: 'channelOneId',
        multiple: true,
        options: channelList.map((item) => ({ ...item, label: item.name, value: item.id })),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'channelTwoId',
        multiple: true,
        options: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'channelThreeId',
        multiple: true,
        options: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'channelFourId',
        multiple: true,
        options: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'channelFiveId',
        multiple: true,
        options: [],
      })
      // 获取客服
      const params = { roleLogo: '1014008' }
      const staffList = await adminService.getStaffList(params)
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '客服',
        field: 'hatchUserIds',
        multiple: true,
        options: staffList.map(({ id, name }) => ({ id, label: name, value:id })),
      })
      this.filterOptions = ret
    },
    goDetail({ id }){
      this.$router.push({
        path:'/clue-incubation/details',
        query:{
          id,
        }
      })
    },
    // 单选
    onCheckChanged(item) {
      const index = this.distributionIds.findIndex(id=>id === item.id)
      if (index !== -1){
        this.distributionIds.splice(index,1)
      } else {
        this.distributionIds.push(item.id)
      }
    },
    // 全选
    onAllChecked() {
      console.log(this.$refs.list.list)
      this.$refs.list.list.forEach((item) => {
        if (!this.distributionIds.includes(item.id)){
          this.distributionIds.push(item.id)
        }
      })
    },
    // 取消
    cleanDistribution() {
      this.distributionIds = []
      this.isDistributionState = false
    },
    // 分配 打开员工选择面板
    async onDistribution() {
      if (!this.distributionIds.length)
        return this.$toast(this.$t('请选择待分配的线索'))
      await this.getHatchRuleDetail()
      this.showDistribution = true
    },
    async distributionFilter({ paraAccountNo }) {
      if (paraAccountNo){
        const emp = this.relateEmployee.find(({ id })=>id===paraAccountNo)
        const params = {
          empId: paraAccountNo,
          empName: emp.name,
          ids: this.distributionIds,
          // roleLogo: this.distributionRole,
        }
        const res = await syncLeadAllocation(params)
        this.onRefresh()
      }
    },
    async getHatchRuleDetail() {
      // getHatchRuleDetail({}).then(res=>{
      //   const { relateEmployee = [] } = res || {}
      //   this.relateEmployee = arrayFormat(relateEmployee,{
      //     value: 'userId',
      //     name:'userName',
      //     nameKey:'name',
      //     cb:(item)=>({ id:item.userId,uuid: item.userId })
      //   })
      // })
    
      const res = await baseDataServices.getStaffList({ roleLogo: '1014008' })
      this.relateEmployee = res?.map(({ id, name }) => ({ id, name, value: id })) || []
    }
  }
}
</script>
<style lang="less" scoped>

.total{
  height: 22px;
  font-size: 14px;
  font-family: HYQiHei, HYQiHei;
  font-weight: normal;
  color: #262626;
  line-height: 22px;
  margin: 9px 16px 0;
}

</style>
